@import '../../mixins/inner';
@import '../../mixins/respond';

.site-top {
    top: 0;
    position: sticky;
    margin-bottom: var(--gutter-size);
    background: white;
    z-index: 3;

    .inner {
        @extend %inner;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @include respond-to(mobile) {
        margin-bottom: 0;
    }
}
