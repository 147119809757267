@import '../../shared/settings';

.hours-table {
    display: grid;
    width: 100%;
    line-height: 26px;
    grid-template-columns: auto 1fr;
    grid-column-gap: 40px;

    div {
        @extend %nowrap;

        &.current {
            font-weight: bold;
        }
    }

    @include respond-to(mobile) {
        margin-bottom: 0;
    }
}
