@import '../../mixins/respond';

.site-header {
    background-color: var(--site-top-surface);
    color: var(--site-top-on-surface, white);

    .inner {
        height: var(--header-height);
        @include respond-to(mobile) {
            height: var(--header-height-mobile);
            padding: 0 var(--gutter-size);
        }
        > a {
            color: inherit;
            text-decoration: underline;
        }
    }



    .inline {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        cursor: pointer;
        height: 24px;
        gap: var(--gutter-size);
        line-height: 22px;

        span {
            position: relative;
            display: block;

            &:has(svg) {
                height: 24px;
                padding: 0;
            }
        }

        .badge {
            position: absolute;
            top: -9px;
            left: 24px - 9px;  
         }

        & > span > svg {
            fill: var(--site-top-on-surface, white);
            height: 24px;
            width: 24px;
        }
    }

    .buttons {
        margin-left: auto;

        .inline {
            margin: 0 var(--gutter-size);

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .buttons,
    .branding {
        display: flex;
    }

    .search {
        flex: 1;
        position: relative;
    }

    .logo {
        display: block;

        svg {
            height: 44px;
            width: auto;
            display: block;
        }
    }

    .search {
        .searchbox {
            input[type='text'] {
                border-radius: 36px;
            }
        }
        .omni-search-dropdown {
            top: 58px;
        }
        .icon-search {
            fill: var(--sys-color-light-on-surface, --site-top-surface, black);
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
    }

    @include respond-to(mobile) {
        .branding {
            align-items: center;
            gap: 16px;

            .logo {
                svg {
                    height: 30px;
                }
            }
        }
        .buttons {
            padding-right: var(--gutter-size);
            .inline {
                margin: 0 0 0 var(--gutter-size);
            }
        }
    }

    @include respond-to(not-mobile) {
        .branding {
            padding-right: calc(var(--gutter-size) * 4);
        }
        .buttons {
            padding-left: calc(var(--gutter-size) * 4);
        }
    }
}

.tray-sidebar .omni-search-dropdown {
    position: static;
}
