@import '../../shared/settings';

figure {
    position: relative;
    margin: 0;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &.cover {
        background-size: cover;
        img {
            object-fit: cover;
        }
    }

    > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        width: 50%;
        height: auto;
        fill: $light-grey;
    }
}

.figure-loading {
    background: $light;
}
