@import '../../shared/settings';
@import '../../mixins/inner';

.site-header-location {
    background: white;
    color: var(--dark-grey);
    border-bottom: 1px solid var(--border);

    .inline > span > svg {
        fill: var(--dark-grey);
    }

    .search {
        .icon-search {
            fill: var(--dark-grey);
        }
    }
}

.location-when-open {
    display: flex;
    align-items: center;
    svg {
        margin-right: 10px;
    }
    a {
        color: inherit;
    }
}
