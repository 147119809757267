@import '../../shared/settings';
@import '../../mixins/inner';

.site-content {
    @include respond-to(not-mobile) {
        min-height: calc(100vh - 485px);
    }

    .inner {
        @extend %inner;
    }

    @include respond-to(mobile) {
        &.discover-page {
            .inner {
                margin-top: 0;
            }
        }

        .inner {
            padding: 0;
        }
    }
}
