@import '../../shared/settings';
@import '../../mixins/inner';

.site-messages {
    margin: -20px 0 0;

    .inner {
        @extend %inner;
        padding: 0;
        max-width: none;
    }

    @include respond-to(mobile) {
        margin: 0;
    }
}

.site-message {
    margin: 20px 0;
    background-color: rgba($light, .5);
    padding: 10px 20px;

    p {
        margin: 0;
        text-align: center;
        line-height: 20px;
        font-size: 13px;
    }

    @include respond-to(mobile) {
        position: relative;
        margin: 0;
        background-color: rgba($light, .5);
        padding: 10px 20px;
        height: auto;

        p {
            line-height: 18px;
            font-size: 12px;
        }
    }
}
