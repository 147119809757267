@import '../../shared/settings';

@keyframes progress {
    0% {
        background-position: -200px 0;
    }

    100% {
        background-position: calc(200px + 100%) 0;
    }
}

.skeleton-loading {
    display: inline-block;
    animation: progress 1.2s ease-in-out infinite;
    border-radius: 2px;
    background-color: rgba($light-grey, 0.2);
    background-repeat: no-repeat;
    background-size: 200px 100%;
    width: 100%;

    &.skeleton-dark {
        background-color: $dark-grey;
        background-image: linear-gradient(
            90deg,
            $dark-grey,
            mix($dark-grey, white, 80%),
            $dark-grey
        );
    }

    &.skeleton-brand {
        background-color: $brand;
        background-image: linear-gradient(
            90deg,
            $brand,
            mix($brand, white, 80%),
            $brand
        );
    }

    &.skeleton-success {
        background-color: var(--success);
        background-image: linear-gradient(
            90deg,
            $success,
            mix($success, white, 80%),
            $success
        );
    }

    &.skeleton-warning {
        background-color: var(--warning);
        background-image: linear-gradient(
            90deg,
            $warning,
            mix($warning, white, 80%),
            $warning
        );
    }

    &.skeleton-danger {
        background-color: var(--danger);
        background-image: linear-gradient(
            90deg,
            $danger,
            mix($danger, white, 80%),
            $danger
        );
    }

    &.skeleton-white {
        background-color: white;
        background-image: linear-gradient(
            90deg,
            white,
            mix(white, $grey, 80%),
            white
        );
    }
}
