@import '../../shared/settings';
@import '../../mixins/inner';

.site-footer {
    line-height: 34px;
    font-size: 16px;

    @include respond-to(mobile) {
        line-height: 28px;
    }

    .inner {
        @extend %inner;
        padding: 48px 24px 26px;
    }

    a {
        color: var(--dark-grey);
        text-decoration: none;

        &:hover {
            color: var(--brand);
        }
    }

    h4 {
        margin: 0 0 4px 0;
        line-height: 32px;

        &:after {
            height: 2px;
            width: 50px;
            background-color: var(--brand);
            content: ' ';
            display: block;
        }
    }

    @include respond-to(mobile) {
        .inner {
            padding: 24px 20px;
        }

        h4 {
            margin: 0 0 8px;
        }
    }
}

.footer-links {
    .inner {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 16px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    @include respond-to(mobile) {
        .inner {
            grid-template-columns: auto;
            grid-template-rows: auto;
            grid-row-gap: 24px;
        }
    }
}

.footer-links + .footer-copyright {
    margin-top: 0;
    border-top: 0;
}

.footer-copyright {
    line-height: 26px;
    font-size: 16px;

    .inner {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 26px 24px 48px;
    }

    .powered-by {
        position: relative;
        padding: 0 64px 0 0;
        text-align: right;

        svg {
            position: absolute;
            top: 0;
            right: 0;
            width: 56px;
            height: 24px;

            .logomark,
            .wordmark {
                fill: var(--brand);
            }
        }
    }

    @include respond-to(mobile) {
        text-align: left;

        .inner {
            display: block;
            padding: 24px 20px 32px;

            span {
                padding: 0;
            }

            .powered-by {
                margin: 24px 0 0;
                text-align: left;

                svg {
                    top: -1px;
                    right: auto;
                    margin-left: 8px;
                }
            }
        }
    }
}
