@import '../../shared/settings.scss';

.searchbox {
    position: relative;

    input[type='text'] {
        padding-left: 56px; // 16px + 24px + 16px
        padding-right: 50px; // 16px + 18px + 16px
    }

    .icon-search {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        width: 24px;
        height: 24px;
        fill: var(--sys-color-light-on-surface, #434D56)
    }

    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 17px;
    }
}
