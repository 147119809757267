@import '../../shared/settings.scss';

.deeper-menu {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

@include respond-to(not-mobile) {
    .new-menu,
    .deeper-menu {
        a {
            color: $dark-grey;
        }
    }

    .new-menu {
        display: grid;
        border-bottom: 1px solid rgba($light-grey, 0.5);
        padding: 14px 0;
        grid-template-columns: 250px 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 16px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        a {
            color: $dark-grey;
        }

        > ul:first-child {
            li {
                line-height: 36px;

                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    transition: background 200ms;
                    margin-left: -10px;
                    padding: 0 10px;

                    svg {
                        transition: opacity 200ms;
                        opacity: 0.5;
                        height: 8px;
                        fill: $dark-grey;
                    }

                    &.active {
                        background: rgba($light, 0.5);

                        svg {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .deeper-menu {
            padding: 5px 0 0 16px;
        }
    }

    .deeper-menu {
        column-gap: 16px;
        column-count: 3;
        line-height: 26px;
        font-size: 14px;

        a {
            &:hover {
                text-decoration: underline;
                color: $brand;
            }
        }

        > ul {
            margin: 0 0 24px;
            break-inside: avoid-column;

            ul {
                li {
                    a {
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .active {
                    text-decoration: underline;
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .new-menu {
        border-bottom: 0;
        grid-template-columns: 100%;

        ul {
            li {
                line-height: 36px;

                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 -10px;
                    padding: 0 10px;

                    svg {
                        transition: opacity 200ms;
                        opacity: 0.5;
                        width: 5px;
                        height: 8px;
                        fill: $dark-grey;
                    }

                    &:active,
                    &.active {
                        background: rgba($light, 0.5);

                        svg {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .depth-3,
        .active {
            a {
                text-decoration: underline;
            }
        }

        .show-all {
            a {
                opacity: 0.5;
                margin-top: 14px;
                font-size: 14px;
            }
        }
    }
}

@include respond-to-min-max(768px, 1024px) {
    .new-menu {
        .deeper-menu {
            column-count: 1;
        }
    }
}

@include respond-to-min-max(1024px, 1280px) {
    .new-menu {
        .deeper-menu {
            column-count: 2;
        }
    }
}
