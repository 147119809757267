// theme colors
$black-friday:              #1a1a1a;
$christmas:                 #144230;
$saint-nicholas:            #891918;
$new-years-eve:             #1e0e35;

$product-christmas:         #3ba342;
$product-black-friday:      #474e53;
$product-saint-nicholas:    #e74c3c;
$product-new-years-eve:     #5d2e8c;
