@keyframes slide-from-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-from-top {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}


@keyframes slide-from-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes backdrop-fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


dialog {
    &:modal {
        max-height: unset;
        max-width: unset;
        position: fixed;

        border: none;
        margin: 0;
        padding: 0;
    }
    &::backdrop {
        background: rgba(0,0,0,.75);
    }
}

.dialog-left,
.dialog-right {

    &:modal {
        bottom: 0;
        top: 0;
        width: 90vw;
        max-width: 600px;
    }

    & > div {
        height: 100vh;
        width: 100%;
        background-color: white;
    }
}

.dialog-top {
    &:modal {
        top: 132px;
        width: 100vw;
    }
    &::backdrop {
        top: 132px;
    }
}


.dialog-left:modal {
    left: 0;
    right: unset;
}

.dialog-right:modal {
    left: unset;
    right: 0;
}

.dialog-open-animation {
    &::backdrop {
        animation: backdrop-fade 300ms ease forwards;
    }
}

.dialog-close-animation {
    &::backdrop {
        animation: backdrop-fade 300ms ease backwards;
        animation-direction: reverse;
    }
}

.dialog-top.dialog-open-animation {
    animation: slide-from-top 300ms ease forwards;
}
.dialog-top.dialog-close-animation {
    animation: slide-from-top 300ms ease backwards;
    animation-direction: reverse;
}

.dialog-left.dialog-open-animation {
    animation: slide-from-left 300ms ease forwards;
}
.dialog-left.dialog-close-animation {
    animation: slide-from-left 300ms ease backwards;
    animation-direction: reverse;
}

.dialog-right.dialog-open-animation {
    animation: slide-from-right 300ms ease forwards;
}
.dialog-right.dialog-close-animation {
    animation: slide-from-right 300ms ease backwards;
    animation-direction: reverse;
}



.dialog-header {
    position: sticky;
    top: 0;
    padding: var(--gutter-size);
    border-bottom: 1px solid var(--border);
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    h3 {
        margin:0;
        line-height: 26px;
        font-size: 16px;
        font-weight: bold;
    }
    a {
        &.logo {
            svg {
                height: 30px;
                width: auto;
            }

            // in case of text;
            color: var(--dark-grey);
            font-weight: bold;
            font-size: 21px;
            line-height: 36px;
        }
    }

}

.dialog-form {
    padding: var(--gutter-size);

    p:first-child {
        margin-top: 0;
    }
}